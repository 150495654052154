import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import eljardinclub1 from "./eljardinclub1.jpeg";
import eljardinclub2 from "./eljardinclub2.jpeg";
import eljardinclub3 from "./eljardinclub3.jpeg";
import eljardinclub4 from "./eljardinclub4.jpeg";
import eljardinclub5 from "./eljardinclub5.jpeg";
import eljardinclub6 from "./eljardinclub6.jpeg";
import eljardinclub7 from "./eljardinclub7.jpeg";
import eljardinclub8 from "./eljardinclub8.jpeg";
import eljardinclub9 from "./eljardinclub9.jpeg";

const GalleryCarousel = () => {
  return (
    <div
      className="swiper-container"
      style={{
        margin: "4px",
        textAlign: "center",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Our Gallery</h2>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        style={{ maxWidth: "700px", margin: "0 auto", padding: "0 0px" }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
        }}
      >
        <SwiperSlide>
          <img
            src={eljardinclub1}
            alt="El Jardin Club 1"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={eljardinclub2}
            alt="El Jardin Club 2"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={eljardinclub3}
            alt="El Jardin Club 3"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>

        <SwiperSlide>
          <img
            src={eljardinclub6}
            alt="El Jardin Club 6"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={eljardinclub7}
            alt="El Jardin Club 7"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={eljardinclub8}
            alt="El Jardin Club 8"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={eljardinclub9}
            alt="El Jardin Club 9"
            style={{
              width: "600px",
              height: "600px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default GalleryCarousel;
