import React from "react";
import { Container } from "@mui/system";
import "./index.css";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Footer = () => {
  const { formatMessage } = useIntl();

  const mapContainerStyle = {
    width: "300px",
    height: "200px",
  };

  const center = {
    lat: 28.076433,
    lng: -16.734877,
  };

  const address =
    "Cartier San Eugenio Calle Gran Bretaña 18 local 2 Centro Comercial Krystal 38660 Adeje, Tenerife";
  const encodedAddress = encodeURIComponent(address);
  const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;

  return (
    <div className="footer-wrapper">
      <Container maxWidth="xl" className="footer-container">
        <div className="footer">
          <div className="footer-map-container">
            <div className="footer-map">
              <LoadScript googleMapsApiKey="AIzaSyCCiE_zDAqyiF4Dio863h1EOBIhJLSHs0U">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={15}
                >
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript>
            </div>
            <div className="footer-address">
              <h4>El Jardin Verde Association</h4>
              <p>Cartier San Eugenio</p>
              <p>Calle Gran Bretaña 18 local 2</p>
              <p>Centro Comercial Krystal</p>
              <p>38660 Adeje, Tenerife</p>
              <a
                href={directionsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="get-directions-link"
              >
                Get Directions
              </a>
              <div className="footer-opening-hours">
                <h4>Opening Hours</h4>
                <p>Monday - Saturday: 15:00 PM - 23:00 PM</p>
                <p>Sunday: Closed</p>
              </div>
            </div>
          </div>
          <div className="footer-content">
            <div className="footer-copyright">
              ©2024 El Jardin Verde Association Tenerife
            </div>
            <div className="footer-links">
              <Link
                to={formatMessage({ id: "links.terms" })}
                className="footer-legal-link"
              >
                Terms
              </Link>
              <Link
                to={formatMessage({ id: "links.privacy" })}
                className="footer-legal-link"
              >
                Privacy
              </Link>
              <Link to="/" className="footer-legal-link">
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;